import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import atoms from '../../atoms'
import coverImage from '../../../assets/Images/coverImage.jpeg'
import constant from '../../../constants/constant'
// import CopyIcon from '../../../assets/Images/copy-icon.svg'
import './index.scss'
import { RxCross2 } from "react-icons/rx";
import { BASE_URL } from '../../../constants/defaultValues'
import moment from 'moment'

const { Button, Image, Table } = atoms
const SidePopUp = (props: any) => {
    const handleUpdateStatus = (status: number) => {
        props.updateVerifiactionStatus(props?.isViewData?.id, status)
        props.onHide()
    }

    const getNestedData = (data: any, type: string) => {
        let temp = 0
        data && data.map((value: any, index: number) => {
            if (type === 'quantity') {
                temp += value[type]
            } else {
                temp += value.product[type]
            }
        })
        return temp
    }
    const columns = [
        { name: 'Product Type', selector: (row: any) => row?.product?.product_type?.name, sortable: true },
        { name: 'Cover Photo', selector: (row: any) => <img src={row?.product?.images[0]} className='w-50 rounded ' /> },
        { name: 'Brand', selector: (row: any) => row?.product?.brand?.brand_name },
        { name: 'Model Number', selector: (row: any) => row?.product?.modal_number },
        { name: 'Glass Size', selector: (row: any) => row?.product?.glass_size },
        { name: 'SRP (₹)', selector: (row: any) => row?.product.product_mrp },
        { name: 'QTY', selector: (row: any) => row?.quantity },
        { name: 'Amount', selector: (row: any) =>Number(row.total_price || 0)?.toFixed(2) },
    ]
    return (
        <Modal className="rounded-0"
            isOpen={props.show}
            wrapClassName="modal-right"
        >
            <ModalHeader className="d-flex border-0 position-relative" >
                {/* <div className="mt-4 fs-24 fw-600">{props.header}</div> */}
                <div className="mt-4 fs-24 fw-600 mb-0">Inventory</div>
                <div className="position-absolute py-1 px-2" style={{ top: '15%', right: '3%', backgroundColor: 'transparent', border: '1px solid #222', borderRadius: '50%', cursor: 'pointer' }} onClick={props.onHide}><RxCross2 style={{ color: '#222' }} /></div>
            </ModalHeader>
            <ModalBody className="pt-md-0">
                <div className=' d-flex gap-3 align-items-center mb-4'>
                    <h2 className='mb-0 fs-18 fw-700 secondary-color '>Invoice Addition ID:</h2>
                    <div className='d-flex justify-content-around align-items-center py-2 add-product-custom-div cursor-pointer' >
                        <h2 className='mb-0 fs-16 fw-600 secondary-color'>{props?.isViewData?.invoice_id}</h2>
                        <div className=''>
                            <Image src={"CopyIcon"} onClick={() => navigator.clipboard.writeText(props?.isViewData?.invoice_id)} />
                        </div>
                    </div>

                </div>
                <hr className="mt-3" style={{ border: '1px dashed #222', }} />

                <div className='row mt-4'>
                    <div className="col-4 fs-20 fw-800 secondary-color">Physical Store</div>
                    <div className="col-4 fs-20 fw-800 secondary-color">Supplier Invoice No</div>
                    <div className="col-4 fs-20 fw-800 secondary-color"> Supplier Invoice Date</div>
                </div>


                <div className='row mt-4'>
                    <div className="col-4 mt-2 fs-20 fw-400 secondary-color">{props.isViewData?.physical_store?.store_name}</div>
                    <div className="col-4 mt-2 fs-20 fw-400 secondary-color">{props.isViewData.supplier_invoice_number}</div>
                    <div className="col-4 mt-2 fs-20 fw-400 secondary-color">{moment(props.isViewData.supplier_invoice_date).format('DD-MM-YYYY')}</div>
                </div>
                <div className='row mt-4'>
                    <div className="col-4 mt-2 fs-20 fw-800 secondary-color">Supplier Details</div>
                    <div className="col-4 mt-2 fs-20 fw-800 secondary-color">Created Date</div>
                    <div className="col-4 mt-2 fs-20 fw-800 secondary-color">Actual Invoice Value</div>
                </div>

                <div className='row mt-4'>
                    <div className="col-4 mt-2 fs-20 fw-400 secondary-color">{props.isViewData?.supplier?.supplier_name}</div>
                    <div className="col-4 mt-2 fs-20 fw-400 secondary-color">{moment(props.isViewData.createdAt).format('DD-MM-YYYY')}</div>
                    <div className="col-4 mt-2 fs-20 fw-400 secondary-color">{props.isViewData.actual_invoice}</div>
                </div>
                <div className='row mt-4'>
                    <div className="col-4  fs-20 fw-800 secondary-color">Computed Invoice Value</div>
                    <div className="col-4  fs-20 fw-800 secondary-color">Total Product Case </div>
                    <div className="col-4  fs-20 fw-800 secondary-color">Total GST</div>
                </div>
                <div className='row mt-4'>
                    <div className="col-4 mt-2 fs-20 fw-400 secondary-color">{props.isViewData.total_amount}</div>
                    <div className="col-4 mt-2 fs-20 fw-400 secondary-color">{props.isViewData.total_product_case}</div>
                    <div className="col-4 mt-2 fs-20 fw-400 secondary-color">{props.isViewData.total_gst}</div>
                </div>

                <div>
                    {props.isViewData?.product_details ? (
                        <Table columns={columns}  AdditionalClassName="table-height-50" data={props.isViewData?.product_details} />) : "No Data"}
                </div>
                <hr className="mt-5" style={{ border: '1px dashed #222', }} />
                <div className="row">
                    <div className="col-7 fs-20 fw-800 secondary-color">Total</div>
                    <div className="col-1 text-end">{props.isViewData.total_amount}</div>
                    <div className="col-1 text-end">{getNestedData(props.isViewData.product_details, "quantity")}</div>
                    <div className="col-2 text-end">{getNestedData(props.isViewData.product_details, "product_mrp")}</div>
                    <div className="col- "></div>
                </div>
                <hr className="mt-5" style={{ border: '1px dashed #222', }} />
                <div className='row mt-4'>
                    <div className="col-4 fs-20 fw-600 secondary-color">Remarks</div>

                </div>
                <div className='row mt-4 add-inventory-custom-div' >
                    <div className="col-4 fs-20 fw-600 secondary-color">{props?.isViewData?.remark}</div>

                </div>


            </ModalBody>


            <ModalFooter className="border-0 d-flex" style={{ flexWrap: 'inherit' }}>
                <Button className="fs-16 fw-500 reject-btn" onClick={() =>
                    handleUpdateStatus(constant.VERIFICATION_STATUS.REWORK)
                }>
                    Reject
                </Button>{" "}
                <Button className="fs-16 fw-500 approve-btn" onClick={() =>
                    handleUpdateStatus(constant.VERIFICATION_STATUS.VERIFIED)
                }>
                    Approve
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default SidePopUp