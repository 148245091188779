import React from 'react'
import { Navigate } from 'react-router'
import privatePages from '../pages/App'
import publicPages from '../pages/Auth'
const { Dashboard, ProductList, ProductDetail, StaffAdd, Staff, OrderCart, OrderHistory, OrderHistoryItems,
    OrderReceived, OrderCancelled, TransferRequested, TransferRequestMoveOut, TransferRequestCancelled, InventoryReport,
    Inventory, Transaction, GeneralAccount, ChangePassword, InventoryAudit, Brand, DailyActivityReport, GSTOutputReport, SalesReport,InventoryAdditionReport,
    InventoryDashboard, OrderDashboard, AccountsDashboard, SalesReturn,AddInventory,AddProduct,Setting } = privatePages
const { Login, Registration } = publicPages

const privateRoutes = [
    { path: "/", exact: true, component: <Navigate to="/dashboard" />, },
    { path: '/dashboard', component: <Dashboard /> },
    { path: '/inventory/detail', component: <InventoryDashboard /> },
    { path: '/order/detail', component: <OrderDashboard /> },
    { path: '/product/list', component: <ProductList /> },
    { path: '/accounts/gl', component: <AccountsDashboard /> },
    { path: '/product/details/:id', component: <ProductDetail /> },

    { path: '/staff', component: <Staff /> },
    { path: '/staff/add', component: <StaffAdd /> },
    { path: '/staff/edit/:id', component: <StaffAdd /> },
    { path: '/order-cart', component: <OrderCart /> },

    { path: '/procurement/order/history', component: <OrderHistory /> },
    { path: '/procurement/order/history/product-detail/:id', component: <OrderHistoryItems /> },
    { path: '/procurement/order/received', component: <OrderReceived /> },
    { path: '/procurement/cancelled', component: <OrderCancelled /> },
    { path: '/add-product', component: <AddProduct /> },


    { path: '/transfer-request/requested', component: <TransferRequested /> },
    { path: '/transfer-request/moved-out', component: <TransferRequestMoveOut /> },
    { path: '/transfer-request/cancelled', component: <TransferRequestCancelled /> },

    { path: '/inventory', component: <Inventory /> },
    { path: '/inventory/add', component: <AddInventory /> },

    { path: '/accounts/transaction', component: <Transaction /> },
    { path: '/accounts/general-account', component: <GeneralAccount /> },

    { path: '/report/inventory', component: <InventoryReport /> },
    { path: '/report/daily-activity', component: <DailyActivityReport /> },
    { path: '/report/gst', component: <GSTOutputReport /> },
    { path: '/report/sales', component: <SalesReport /> },
    { path: '/report/sales-return', component: <SalesReturn /> },
    { path: '/report/inventory-addition', component: <InventoryAdditionReport /> },

    // for setting page
    { path: '/setting', component: <Setting /> },

    { path: '/change-password', component: <ChangePassword /> },
    { path: '/brand', component: <Brand /> },



]

const PublicRoutes = [
    { path: '/login', component: <Login /> },
    { path: '/registration', component: <Registration /> },
]
export { privateRoutes, PublicRoutes }